import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { createBrowserRouter, useLocation } from 'react-router-dom';
import { Navbar } from '@/components/navbar';
import { Analytics } from '@/services/analytics';
import { ErrorBoundary } from '@/errors/pages/error-boundary';
import { EventPageWrapper } from '@/events/pages/event-page-wrapper-connector';
import { EligibilityCheckerPage } from '@/events/pages/eligibility-checker';

type Props = {
    children: ReactNode;
};

const PAGE_TITLE: string = 'Welcome | Wider Circle';

const PageWrapper: FunctionComponent<Props> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        Analytics.getInstance().sendPageView(location.pathname, PAGE_TITLE);
    }, [location.pathname]);

    return (
        <>
            <Navbar />

            {children}
        </>
    );
};

export const useRouterRoot = () => {
    return createBrowserRouter([
        {
            path: '/',
            element: (
                <ErrorBoundary>
                    <PageWrapper>
                        <EventPageWrapper />
                    </PageWrapper>
                </ErrorBoundary>
            ),
        },
        {
            path: '/eligibility-checker',
            element: (
                <ErrorBoundary>
                    <PageWrapper>
                        <EligibilityCheckerPage />
                    </PageWrapper>
                </ErrorBoundary>
            ),
        },
    ]);
};
