import { useCallback, useEffect } from 'react';
import CryptoJS from 'crypto-js';
// eslint-disable-next-line import/named
import { v4 as generateUUIDV4 } from 'uuid';

import { useQueryParams } from '@/utilities/use-query-params';
import { ELIGIBILITY_CHECKER_QUERY_KEY } from '@/constants/query-params';
import { getNewToken } from '@/authentication/api';
import { TOKEN_PROP_NAME } from '@/authentication/hooks/use-authorization';

export const EligibilityCheckerPage = () => {
    const { getQueryParameter } = useQueryParams();

    const processMember = useCallback(async (contactId: string) => {
        const newToken = await getNewToken(contactId, generateUUIDV4());
        localStorage.setItem(TOKEN_PROP_NAME, newToken);
    }, []);

    const processToken = useCallback(
        (token: string) => {
            const secretPasscode = process.env.REACT_APP_ELIGIBILITY_CHECKER_PASSCODE || '';

            const encryptedHexCipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Hex.parse(token),
            });

            const contactId = CryptoJS.AES.decrypt(encryptedHexCipherParams, secretPasscode);
            console.log('CONTACT ID', contactId.toString(CryptoJS.enc.Utf8));
            processMember(contactId.toString(CryptoJS.enc.Utf8));
        },
        [processMember]
    );

    useEffect(() => {
        const token = getQueryParameter(ELIGIBILITY_CHECKER_QUERY_KEY);

        if (!token) {
            throw new Error('NO PARAMETER PROVIDED');
        }

        processToken(token);
    }, [processToken]);

    return <></>;
};
